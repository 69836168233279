import React, { useState, useEffect, useCallback, createRef } from "react";
import BASE_URL from "../../constants";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Drawer,
  Button,
  SelectPicker,
  List,
  Panel,
  Modal,
  Loader,
} from "rsuite";
import axios from "axios";
import ImageViewer from "react-simple-image-viewer";
import ImageZoom from "react-medium-image-zoom";

// import "./gallery.css";
const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageName, setImageName] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [updatedrawer, setUpdateDrawer] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [newdata, setNewData] = useState([]);
  const [newname, setNewname] = useState("");
  const [updatenameDrawer, setUpdatenameDrawer] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/all_photo_albums`).then((response) => {
      setData(response.data.all_photos);
      console.log(response.data.all_photos.imgCollection);
    });
  }, []);

  const onFileChange = (e) => {
    setImages(e.target.files);
  };

  const updateName = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        albumid: newdata.albumid,
        name: newname,
      }),
    };

    fetch(`${BASE_URL}/updatephotoname`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.danger("Some error occured please check your data");
        } else {
          closeNameDrawerUpdate();
          Alert.success("Sucessfully Updated  !!");
          axios.get(`${BASE_URL}/all_photo_albums`).then((response) => {
            setData(response.data.all_photos);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    for (const key of Object.keys(images)) {
      formData.append("imgCollection", images[key]);
    }
    formData.append("name", imageName);

    axios.post(`${BASE_URL}/newalbum`, formData, {}).then((res) => {
      if (res.err) {
        Alert.danger(res);
      } else {
        setLoading(false);
        setDrawer(false);
        axios.get(`${BASE_URL}/all_photo_albums`).then((response) => {
          setData(response.data.all_photos);
        });

        Alert.success("Sucessfully Added new album !!");
      }
    });
  };

  const onSubmitUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", newname);
    for (const key of Object.keys(images)) {
      formData.append("imgCollection", images[key]);
      formData.append("albumid", newdata.albumid);
    }

    axios.put(`${BASE_URL}/updatealbum`, formData, {}).then((res) => {
      if (res.err) {
        Alert.danger(res);
      } else {
        setLoading(false);
        setUpdateDrawer(false);
        axios.get(`${BASE_URL}/all_photo_albums`).then((response) => {
          setData(response.data.all_photos);
        });

        Alert.success("Sucessfully Updated new album !!");
      }
    });
  };

  const deletePost = (photoId) => {
    fetch(`${BASE_URL}/deletephotoalbum/${photoId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = data.filter((item) => {
          return item._id !== result._id;
        });
        axios.get(`${BASE_URL}/all_photo_albums`).then((response) => {
          setData(response.data.all_photos);
        });

        setDeleteModal(false);
      });
  };

  //   OPEN ADD PICS DRAWER
  const openDrawer = () => {
    setDrawer(true);
  };
  const closeDrawer = () => {
    setDrawer(false);
  };

  //   OPEN UPDATE PICS DRAWER
  const openDrawerUpdate = () => {
    setUpdateDrawer(true);
  };
  const closeDrawerUpdate = () => {
    setUpdateDrawer(false);
  };

  //   OPEN UPDATE PICS DRAWER
  const openNameDrawerUpdate = () => {
    setUpdatenameDrawer(true);
  };
  const closeNameDrawerUpdate = () => {
    setUpdatenameDrawer(false);
  };

  //   OPEN UPDATE PICS DRAWER
  const openDelete = () => {
    setDeleteModal(true);
  };
  const closeDelete = () => {
    setDeleteModal(false);
  };

  const change = (src) => {
    document.getElementById("main").src = src;
  };
  return (
    <div
      style={{
        padding: 100,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        color="green"
        style={{ marginLeft: 60, padding: 15 }}
        onClick={openDrawer}
      >
        + Add New Album{" "}
      </Button>

      <List style={{ width: "100%", marginTop: 30, padding: 50 }}>
        {data.map((item, index) => (
          <div>
            <List.Item
              key={item._id}
              style={{
                display: "flex",
                margin: 10,
                fontSize: 13,
              }}
            >
              <div style={{ flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexBasis: "70%",
                    flexWrap: "wrap",
                  }}
                >
                  <p
                    style={{
                      color: "red",
                      width: "100%",
                      fontSize: 20,
                      backgroundColor: "#1a2980",
                      color: "white",
                      fontWeight: 200,
                      borderRadius: 5,
                      paddingLeft: 10,
                    }}
                  >
                    {item.name} {`                     `}|| Totally{"   "}
                    <b>{item.imgCollection.length} </b>
                    Photos
                  </p>
                  {/* <img
                    src={index}
                    style={{ width: 200, height: 200 }}
                  /> */}

                  {item.imgCollection.map((src, index) => (
                    <>
                      {/* <img
                        src={stackimage}
                        style={{ width: 400, height: 200 }}
                      /> */}

                      <ImageZoom
                        zoomMargin={4}
                        image={{
                          src: src,
                          className: "img",
                          style: { width: 42, height: 42, margin: 3 },
                        }}
                        zoomImage={{
                          src: src,
                          style: { width: "50em" },
                        }}
                      />
                      {/* <img
                        src={src}
                        // onClick={() => setStackImage(src.find(index))}
                        // onClick={() => openImageViewer(index)}
                        style={{ width: 40, height: 40, margin: 3 }}
                        key={index}
                        className="image-alert"
                      /> */}
                    </>
                  ))}
                  {isViewerOpen && (
                    <ImageViewer
                      src={item.imgCollection}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                    />
                  )}
                  {/* <p
                    style={{
                      marginLeft: 10,
                      color: "white",
                      fontSize: 20,
                      backgroundColor: "#ff8008",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  >
                    Total <b>{item.imgCollection.length} </b>Photos
                  </p> */}
                </div>
                <div style={{ display: "flex", flexBasis: "70%" }}>
                  <Button
                    style={{
                      float: "right",
                      marginTop: 10,
                      marginRight: 20,
                    }}
                    color="blue"
                    onClick={() => {
                      openDrawerUpdate();
                      setNewData({
                        albumid: item._id,
                      });
                    }}
                  >
                    Add More pictures to album
                  </Button>{" "}
                  <Button
                    style={{
                      float: "right",
                      marginTop: 10,
                      marginRight: 20,
                    }}
                    color="orange"
                    onClick={() => {
                      openNameDrawerUpdate();
                      setNewData({
                        albumid: item._id,
                        name: item.name,
                      });
                      setNewname(item.name);
                    }}
                  >
                    Edit Title
                  </Button>{" "}
                  <Button
                    style={{
                      float: "right",
                      marginTop: 10,
                    }}
                    color="red"
                    onClick={() => {
                      openDelete();
                      setNewData({
                        _id: item._id,
                      });
                    }}
                  >
                    Delete Album
                  </Button>{" "}
                </div>
              </div>
            </List.Item>
            <br />

            {/* MODAL CONTENT */}
            <Modal backdrop={true} show={deletemodal} onHide={closeDelete}>
              <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure want to delete this Album ?</Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="primary"
                  onClick={() => {
                    deletePost(newdata._id);
                  }}
                >
                  Ok
                </Button>
                <Button onClick={closeDelete} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            {/* DRAWER CONTENT */}
            <Drawer
              size="xs"
              placement="right"
              show={drawer}
              onHide={closeDrawer}
            >
              <Drawer.Header>
                <Drawer.Title>Add New Pictures to your album</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                {loading ? (
                  <div
                    style={{
                      marginTop: "60%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p> Uploading Please wait on</p>
                    <br />
                    <Loader size="lg" />
                  </div>
                ) : (
                  <>
                    <br /> <br />
                    <div className="form-group">
                      <form
                        onSubmit={(e) => onSubmit(e)}
                        enctype="multipart/form-data"
                      >
                        <label>
                          AlbumTitle
                          <input
                            style={{ padding: 30, marginTop: 10 }}
                            type="text"
                            value={imageName}
                            onChange={(e) => setImageName(e.target.value)}
                            placeholder="enter album title "
                          />
                        </label>
                        <br />
                        <input
                          type="file"
                          onChange={(e) => onFileChange(e)}
                          accept="image/*"
                          multiple
                        />

                        <br />
                        <button
                          style={{
                            padding: 20,
                            width: "90%",
                            color: "#fff",
                            backgroundColor: "tomato",
                          }}
                        >
                          Add new album
                        </button>
                      </form>
                    </div>
                    <br />
                    <p style={{ color: "orange", fontSize: 13 }}>
                      <b>*** SUMMARY</b>
                      <br /> * please photos all together).
                      <br />
                    </p>
                  </>
                )}
              </Drawer.Body>
              <Drawer.Footer></Drawer.Footer>
            </Drawer>

            {/* DRAWER CONTENT UPDATE */}
            {/* DRAWER CONTENT */}
            <Drawer
              size="xs"
              placement="left"
              show={updatedrawer}
              onHide={closeDrawerUpdate}
            >
              <Drawer.Header>
                <Drawer.Title>Update Pictures to your album</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                {loading ? (
                  <div
                    style={{
                      marginTop: "60%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p> Uploading Please wait on</p>
                    <br />
                    <Loader size="lg" />
                  </div>
                ) : (
                  <>
                    <br /> <br />
                    <p>Add More photos to album</p>
                    <div className="form-group">
                      <form
                        onSubmit={(e) => onSubmitUpdate(e)}
                        encType="multipart/form-data"
                      >
                        <br />
                        <input
                          type="file"
                          onChange={(e) => onFileChange(e)}
                          accept="image/*"
                          multiple
                        />

                        <button
                          style={{
                            padding: 20,
                            color: "#fff",
                            backgroundColor: "yellowgreen",
                          }}
                        >
                          update pictures to album
                        </button>
                      </form>
                    </div>
                    <br />
                    <p style={{ color: "orange", fontSize: 13 }}>
                      <b>*** SUMMARY</b>
                      <br /> * please photos all together).
                      <br />
                    </p>
                  </>
                )}
              </Drawer.Body>
            </Drawer>

            {/* DRAWER CONTENT */}

            <Drawer
              size="xs"
              placement="right"
              show={updatenameDrawer}
              onHide={closeNameDrawerUpdate}
            >
              <Drawer.Header>
                <Drawer.Title>Update sponsor</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <h5 style={{ color: "#ee0979", fontSize: 13 }}>
                  {" "}
                  Update Album name
                </h5>
                <br />

                <label>
                  Name of album e
                  <input
                    type="text"
                    value={newname}
                    onChange={(e) => setNewname(e.target.value)}
                    placeholder="enter title "
                  />
                </label>
                <br />
              </Drawer.Body>
              <Drawer.Footer>
                <Button
                  appearance="primary"
                  color="orange"
                  onClick={() => {
                    updateName();
                  }}
                >
                  Update name
                </Button>
                <Button
                  onClick={closeNameDrawerUpdate}
                  appearance="primary"
                  color="red"
                >
                  Close
                </Button>
              </Drawer.Footer>
            </Drawer>
          </div>
        ))}
      </List>

      {/* DRAWER CONTENT */}
    </div>
  );
};

export default Index;
