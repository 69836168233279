import React, { Component } from "react";
import axios from "axios";
import CKEditor from "ckeditor4-react";
import { Panel } from "rsuite";
import BASE_URL from "../../constants";
import { Alert, Loader } from "rsuite";
import DatePickerx from "react-datepicker";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

class addnew extends Component {
  constructor(props) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setName = this.setName.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.setDate = this.setDate.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.onSingleFileChange = this.onSingleFileChange.bind(this);
    this.postDetaile = this.postDetaile.bind(this);
    this.twoCallssame = this.twoCallssame.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.handleClickback = this.handleClickback.bind(this);

    this.state = {
      loading: false,
      name: "",
      description: "",
      imgCollection: "",
      startDate: new Date(),
      profileImg: "",
      enablebutton: true,
    };
  }

  componentDidMount() {
    // if (!this.state.profileImg.length                                                                                                                                                                                                                                                                                                                                                               == null) {
    this.postDetaile();
    // }
  }

  //ARRAY image upload
  onFileChange(e) {
    this.setState({ imgCollection: e.target.files });
  }

  // SINGLE image upload
  onSingleFileChange(e) {
    this.setState({ profileImg: e.target.files[0] });
  }
  setName(e) {
    this.setState({ name: e.target.value });
  }

  setDescription(e) {
    this.setState({ description: e.target.value });
  }

  setDate(date) {
    this.setState({
      startDate: date,
    });
  }

  setLoading() {
    this.setState({
      loading: true,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    var formData = new FormData();
    for (const key of Object.keys(this.state.imgCollection)) {
      formData.append("imgCollection", this.state.imgCollection[key]);
    } //multiple image upload for loop
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("date", this.state.startDate);
    formData.append("profileImg", this.state.profileImg); //Single image upload
    axios.post(`${BASE_URL}/newproject`, formData, {}).then((res) => {
      if (res.err) {
        Alert.danger(res.err);
      } else {
        Alert.success("Sucessfully Added new project !!");
        this.props.history.push("/projects");
      }
    });
  }

  handleClickback() {
    this.props.history.push("/projects");
  }

  onEditorChange(evt) {
    this.setState({
      description: evt.editor.getData(),
    });
  }

  twoCallssame = (e) => {
    this.onSingleFileChange();
  };

  //FOR UPLOAD COVER IMAGE FIRST
  postDetaile = () => {
    const data = new FormData();
    data.append("file", this.state.profileImg);
    data.append("upload_preset", "employeeapp");
    data.append("cloud_name", "day2qlngn");
    fetch("https://api.cloudinary.com/v1_1/day2qlngn/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        this.setState({
          profileImg: data.url,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div
        style={{
          padding: 30,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Panel
          shaded
          style={{
            paddingLeft: 100,
            paddingRight: 100,
            alignItems: "center",
            justifyContent: "center",
            width: "80vw",
          }}
        >
          <p
            style={{
              backgroundColor: "violet",
              color: "white",
              marginLeft: -100,
              width: 80,
              padding: 10,
              borderRadius: 50,
              cursor: "pointer",
            }}
            onClick={this.handleClickback}
          >
            Go back
          </p>
          <h3
            style={{
              margin: 20,
              marginLeft: -20,
              color: "#ee0979",
            }}
          >
            Add New project Here
          </h3>

          <div className="row">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label>
                  Project Title
                  <input
                    type="text"
                    value={this.state.name}
                    onChange={this.setName}
                    placeholder="enter title of project"
                  />
                </label>
                <br />
                <label>Project Date </label>
                <br />
                <DatePickerx
                  style={{ width: 700 }}
                  selected={this.state.startDate}
                  onChange={this.setDate}
                  dateFormat="MMMM d, yyyy"
                  className="form-control"
                />
                <br /> <br />
                <label>Upload Cover Picture</label>
                <br />
                <p style={{ color: "red" }}>
                  * please upload cover image first and click upload button
                  right{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <input type="file" onChange={this.onSingleFileChange} />
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      this.postDetaile();
                      this.setState({
                        enablebutton: false,
                      });
                    }}
                    style={{ margin: 10, height: 50 }}
                  >
                    upload
                  </button>
                </div>
                {/* <br /> */}
                <br />
                <label>Project Pictures (All Pictures)</label>
                <input
                  type="file"
                  name="imgCollection"
                  onChange={this.onFileChange}
                  multiple
                  placeholder="choose images"
                />
                <p style={{ color: "red" }}>
                  * please upload more than one image (Max file size 3MB and
                  only .png , .jpg, .jpeg formats allowed to upload)
                </p>
              </div>
              <label>Blog Descriptions</label>
              <br />
              <CKEditor
                data={this.state.description}
                onChange={this.onEditorChange}
              />

              <div className="form-group">
                <br />
                <button
                  className="btn btn-success"
                  type="submit"
                  disabled={this.state.enablebutton}
                >
                  Press to Add
                </button>
              </div>
            </form>
            <p style={{ color: "orange", width: "70%" }}>
              <b>*** SUMMARY</b>
              <br /> * please follow the instructions beacause of bulk upload
              some times server may down , check your internet conectivity and
              please dont upload image with morethan 6MB it kills your
              performance on site).
              <br />
              <br />
              *Click the cover image upload first then only "PRESS ADD" button
              enabled
            </p>
          </div>
        </Panel>
      </div>
    );
  }
}

export default withRouter(addnew);
