import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Drawer,
  Alert,
  Notification,
  Loader,
  SelectPicker,
} from "rsuite";
import { useHistory } from "react-router-dom";
import { Panel, PanelGroup, Modal } from "rsuite";
import axios from "axios";
import BASE_URL from "../../constants";
import "react-datepicker/dist/react-datepicker.css";

const Index = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [projectdata, setProjectData] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [newdata, setNewData] = useState({});

  // Sponsor data
  const [sponsorimage, setSponsorImage] = useState("");
  const [sponsorweblink, setSponsorWeblink] = useState("");
  const [sponsorname, setSponsorName] = useState("");
  const [isourevent, setIsourEvent] = useState(true);
  const [sponsordrawer, setSponsorDrawer] = useState(false);
  const [sponsornewdata, setSponsorNewdata] = useState([]);
  const [url, setUrl] = useState("");

  useEffect(() => {
    Refreshdata();
  }, []);

  const Refreshdata = () => {
    axios.get(`${BASE_URL}/all_videos`).then((response) => {
      console.log(response.data.all_videos);
      setProjectData(response.data.all_videos);
    });
  };

  const postDetaileSponsor = () => {
    const requestOptionsx = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: sponsorname,
      }),
    };

    fetch(`${BASE_URL}/newvideo`, requestOptionsx)
      .then((res) => res.json())
      .then((data) => {
        if (data.err) {
          Alert.danger("Some error occured please check your data");
        } else {
          Alert.success("Video added Successfully");
          setSponsorDrawer(false);
          Refreshdata();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = (tickerId) => {
    fetch(`${BASE_URL}/deletevideo/${tickerId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        setProjectData(newData);
        setOpenModal(false);
        Refreshdata();
      });
  };

  function openDrawer() {
    setSponsorDrawer(true);
  }

  // /For sponsor drawer/

  function closesponsordrawer() {
    setSponsorDrawer(false);
  }

  function closeModal() {
    setOpenModal(false);
  }
  function openModal() {
    setOpenModal(true);
  }

  return (
    <div>
      {loading ? (
        <Loader
          style={{ marginLeft: "40vw", marginTop: "30vh" }}
          size="lg"
          content="We are rendering data please wait"
        />
      ) : (
        <div
          style={{
            padding: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="cyan"
            style={{ marginLeft: 60, padding: 15 }}
            onClick={openDrawer}
          >
            + Add New Video{" "}
          </Button>

          <div
            style={{
              display: "flex",
              margin: 10,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <List style={{ width: "90%", marginTop: 30 }}>
              {projectdata.map((item, index) => (
                <div>
                  <List.Item
                    key={item._id}
                    style={{
                      display: "flex",
                      margin: 10,
                      fontSize: 13,
                    }}
                  >
                    <div style={{ flexDirection: "row" }}>
                      <p style={{ color: "violet", width: "90%" }}>
                        {item.name}
                      </p>
                      <Button
                        color="red"
                        onClick={() => {
                          openModal();
                          setNewData({
                            _id: item._id,
                          });
                        }}
                      >
                        Delete Video
                      </Button>{" "}
                    </div>
                  </List.Item>
                  <br />

                  {/* MODAL CONTENT */}
                  <Modal backdrop={true} show={openmodal} onHide={closeModal}>
                    <Modal.Header>
                      <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure want to delete this Video ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          deletePost(newdata._id);
                        }}
                      >
                        Ok
                      </Button>
                      <Button onClick={closeModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* DRAWER CONTENT */}
                  <Drawer
                    size="xs"
                    placement="right"
                    show={sponsordrawer}
                    onHide={closesponsordrawer}
                  >
                    <Drawer.Header>
                      <Drawer.Title>Add New Video url to sync</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      <br /> <br />
                      <div className="form-group">
                        <label>
                          video Url Youtube
                          <input
                            style={{ padding: 30, marginTop: 10 }}
                            type="text"
                            value={sponsorname}
                            onChange={(e) => setSponsorName(e.target.value)}
                            placeholder="enter video url here "
                          />
                        </label>
                      </div>
                      <br />
                      <p style={{ color: "orange", fontSize: 13 }}>
                        <b>*** SUMMARY</b>
                        <br /> * please add video URL Only one by one).
                        <br />
                      </p>
                    </Drawer.Body>
                    <Drawer.Footer>
                      <Button
                        onClick={() => postDetaileSponsor()}
                        appearance="primary"
                      >
                        Add Video url
                      </Button>
                      <Button onClick={closesponsordrawer} appearance="subtle">
                        Cancel
                      </Button>
                    </Drawer.Footer>
                  </Drawer>
                </div>
              ))}
            </List>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
