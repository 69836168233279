import React, { useState, useEffect } from "react";
import axios from "axios";
import CKEditor from "ckeditor4-react";
import { Panel } from "rsuite";
import BASE_URL from "../../constants";
import { Alert, Loader, SelectPicker } from "rsuite";
import DatePickerx from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useHistory } from "react-router-dom";

const Addevent = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState({});
  const [cover_image, setCover_image] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState();
  const [organizers, setOrganizers] = useState("");
  const [ticketStores, setTicketStores] = useState("");
  const [seatingPlan, setSeatingPlan] = useState("");
  const [locationMap, setLocationMap] = useState("");
  const [ourevent, setOurevent] = useState(true);
  const [location, setLocation] = useState("");
  const [url, setUrl] = useState("");

  var today = new Date();
  var timenow = today.getHours() + ":" + today.getMinutes();

  useEffect(() => {
    setTime(timenow);
  });

  useEffect(() => {
    if (url) {
      fetch(`${BASE_URL}/newevent`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cover_image: url,
          title: title,
          description: description,
          date: date,
          time: time,
          organizers: organizers,
          location: location,
          ticketStores: ticketStores,
          seatingPlan: seatingPlan,
          locationMap: locationMap,
          ourevent: ourevent,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.err) {
            console.log(data.err);
          } else {
            Alert.success("Sucessfully Added new events !!");
            history.push("/events");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [url]);

  const postDetaile = () => {
    const data = new FormData();
    data.append("file", cover_image);
    data.append("upload_preset", "employeeapp");
    data.append("cloud_name", "day2qlngn");
    fetch("https://api.cloudinary.com/v1_1/day2qlngn/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setUrl(data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        padding: 30,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Panel
        shaded
        style={{
          paddingLeft: 100,
          paddingRight: 100,
          alignItems: "center",
          justifyContent: "center",
          width: "80vw",
        }}
      >
        <p
          style={{
            backgroundColor: "violet",
            color: "white",
            marginLeft: -100,
            width: 100,
            padding: 10,
            borderRadius: 50,
            cursor: "pointer",
          }}
          onClick={() => {
            history.push("/events");
          }}
        >
          Go back
        </p>
        <h3
          style={{
            margin: 20,
            marginLeft: -20,
            color: "#ee0979",
          }}
        >
          Add New Event Here
        </h3>

        <div className="row">
          <div className="form-group">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="form-group">
                <label>Event Title </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter title of Event"
                />
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div className="form-group">
                <label>Event Date </label>
                <br />
                <DatePickerx
                  style={{ width: 700 }}
                  selected={date}
                  onChange={(date) => setDate(date)}
                  dateFormat="MMMM d, yyyy"
                  className="form-control"
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="form-group">
                <label>Event Time </label>
                <br />
                {/* <TimePicker
                  // value={time}
                  time={time}
                  onChange={(time) => setTime(time)}
                  style={{ width: 50, padding: 20 }}
                /> */}

                <input
                  style={{ padding: 10, marginTop: -2 }}
                  type="text"
                  placeholder="00:00 PM/AM"
                  onChange={(e) => setTime(e.target.value)}
                />
                {/* <TimePicker onChange={(time) => setTime(time)} />/ */}
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div className="form-group">
                <label>Upload Cover Picture</label>
                <input
                  style={{ padding: 10, marginTop: -2 }}
                  type="file"
                  onChange={(e) => setCover_image(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  marginRight: 10,
                  marginBottom: 10,
                }}
              >
                <label>Event Descriptions</label>
                <br />
                <CKEditor
                  data={description}
                  //   SET DESCRIPTION
                  onChange={(evt) => setDescription(evt.editor.getData())}
                />
                <br />

                <p style={{ color: "orange", fontSize: 14 }}>
                  * format everything as your wish (Main Blog Description is
                  here)
                </p>
              </div>

              <div style={{ flexDirection: "column" }}>
                <label>Event Ticket stores</label>
                <br />
                <CKEditor
                  data={ticketStores}
                  //   SET TICKET STORE
                  onChange={(evt) => setTicketStores(evt.editor.getData())}
                />
                <br />
                <p style={{ color: "orange", fontSize: 14 }}>
                  * please use bullets to seperate or as your pattern right{" "}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ flexDirection: "column", width: "100%" }}>
                <label>Event organizers</label>
                <br />
                <CKEditor
                  data={organizers}
                  //   SET ORGAINZER
                  onChange={(evt) => setOrganizers(evt.editor.getData())}
                />

                <br />
                <p style={{ color: "orange", fontSize: 14 }}>
                  * please use bullets to seperate or as your pattern right{" "}
                </p>

                <br />
                <label>Check our event or others event</label>
                <br />
                <SelectPicker
                  data={[
                    {
                      label: "Ourevent",
                      value: true,
                    },
                    {
                      label: "Others event",
                      value: false,
                    },
                  ]}
                  value={ourevent}
                  onChange={(evt) => setOurevent(evt)}
                  style={{ width: 224 }}
                />
                <h1>{ourevent}</h1>
                <br />
                <br />

                <label>
                  Event Location
                  <br /> <br />
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="enter location of your event"
                  />
                </label>
                <br />
                <br />
                <label>
                  Event Location Google map iFrame
                  <br /> <br />
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    value={locationMap}
                    onChange={(e) => setLocationMap(e.target.value)}
                    placeholder="enter google map iFrames"
                  />
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <button
            className="btn btn-success"
            onClick={() => postDetaile()}
            style={{ paddding: 20, marginRight: 10 }}
          >
            Press to Add New event
          </button>
          <button
            className="btn btn-danger"
            style={{ paddding: 10 }}
            onClick={() => history.push("/events")}
          >
            Exit or Cancel
          </button>
          <br /> <br /> <br /> <br />
          <p style={{ marginTop: 40, color: "orange", width: "70%" }}>
            <b>*** Exceptions</b>
            <br /> * please follow the instructions give some time to upload
            details once it done it automatically redirects so please wait for
            the respose
            <br />
            <br />
            *please put if there is google map location with iframe solidity
            <br />
            <br />
            *For event description , organizers , and ticketStores use bullets
            it diplays as you don in the rich text editor
          </p>
        </div>
      </Panel>
    </div>
  );
};

export default Addevent;
