import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Panel, Paragraph, Icon } from "rsuite";
import axios from "axios";
import BASE_URL from "../../constants";

import { Link } from "react-router-dom";
const Index = () => {
  const [ticker, setTicker] = useState("");
  const [ticker1, setTicker1] = useState("");
  const [carousel, setCarousel] = useState("");
  const [events, setEvents] = useState("");
  const [projects, setProjects] = useState("");
  const [sponsors, setSponsors] = useState("");
  const [album, setAlbum] = useState("");

  useEffect(() => {
    // Ticker
    axios.get(`${BASE_URL}/count_ticker`).then((response) => {
      console.log(response.data.all_ticker_count);
      setTicker(response.data.all_ticker_count);
    });
    axios.get(`${BASE_URL}/count_ticker2`).then((response) => {
      console.log(response.data.all_ticker_count);
      setTicker1(response.data.all_ticker_count);
    });
    // Carousel
    axios.get(`${BASE_URL}/count_carousel`).then((response) => {
      console.log(response.data.all_carousel_count);
      setCarousel(response.data.all_carousel_count);
    });
    // events
    axios.get(`${BASE_URL}/count_events`).then((response) => {
      console.log(response.data.all_events_count);
      setEvents(response.data.all_events_count);
    });
    // Projects
    axios.get(`${BASE_URL}/count_projects`).then((response) => {
      console.log(response.data.all_projects_count);
      setProjects(response.data.all_projects_count);
    });
    // Sponsors
    axios.get(`${BASE_URL}/count_sponsor`).then((response) => {
      console.log(response.data.all_sponsor_count);
      setSponsors(response.data.all_sponsor_count);
    });
    // album
    axios.get(`${BASE_URL}/count_album`).then((response) => {
      console.log(response.data.all_album_count);
      setAlbum(response.data.all_album_count);
    });
  }, []);

  return (
    <div style={stylesz}>
      <Grid fluid>
        <Row className="show-grid">
          <Col xs={24} sm={24} md={8}>
            <Link to="/projects" style={{ textDecoration: "none" }}>
              <Panel shaded bordered bodyFill>
                <Panel
                  style={(styles, { backgroundColor: "red", padding: 20 })}
                >
                  <p
                    style={{
                      fontSize: 45,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      icon="leanpub"
                      style={{
                        fontSize: 45,
                      }}
                    />{" "}
                    {projects}
                  </p>
                  <p
                    style={{
                      fontSize: 25,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    No Of Projects
                  </p>
                </Panel>
              </Panel>
            </Link>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Link to="/events" style={{ textDecoration: "none" }}>
              <Panel shaded bordered bodyFill>
                <Panel
                  style={(styles, { backgroundColor: "orange", padding: 20 })}
                >
                  <p
                    style={{
                      fontSize: 45,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      icon="calendar"
                      style={{
                        fontSize: 45,
                      }}
                    />{" "}
                    {events}
                  </p>
                  <p
                    style={{
                      fontSize: 25,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    No Of Events
                  </p>
                </Panel>
              </Panel>
            </Link>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Panel shaded bordered bodyFill>
              <Link to="/tickers" style={{ textDecoration: "none" }}>
                <Panel
                  style={(styles, { backgroundColor: "green", padding: 20 })}
                >
                  <p
                    style={{
                      fontSize: 45,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      icon="task"
                      style={{
                        fontSize: 45,
                      }}
                    />{" "}
                    {ticker + ticker1}
                  </p>
                  <p
                    style={{
                      fontSize: 25,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    No Of Scrolling Text
                  </p>
                </Panel>
              </Link>
            </Panel>
          </Col>
        </Row>

        {/* sdjigggggggggggggggggggggggggk */}
        <br />
        <Row className="show-grid">
          <Col xs={24} sm={24} md={8}>
            <Link to="/sponsors" style={{ textDecoration: "none" }}>
              <Panel shaded bordered bodyFill>
                <Panel
                  style={(styles, { backgroundColor: "#243b55", padding: 20 })}
                >
                  <p
                    style={{
                      fontSize: 45,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      icon="trend"
                      style={{
                        fontSize: 45,
                      }}
                    />{" "}
                    {sponsors}
                  </p>
                  <p
                    style={{
                      fontSize: 25,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    No Of Sponsors
                  </p>
                </Panel>
              </Panel>
            </Link>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Panel shaded bordered bodyFill>
              <Link to="/carousel" style={{ textDecoration: "none" }}>
                <Panel
                  style={(styles, { backgroundColor: "#834d9b", padding: 20 })}
                >
                  <p
                    style={{
                      fontSize: 45,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      icon="realtime"
                      style={{
                        fontSize: 45,
                      }}
                    />{" "}
                    {carousel}
                  </p>
                  <p
                    style={{
                      fontSize: 25,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    No Of Carousel
                  </p>
                </Panel>
              </Link>
            </Panel>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Panel shaded bordered bodyFill>
              <Link to="/album" style={{ textDecoration: "none" }}>
                <Panel
                  style={(styles, { backgroundColor: "#24c6dc", padding: 20 })}
                >
                  <p
                    style={{
                      fontSize: 45,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      icon="file-image-o"
                      style={{
                        fontSize: 45,
                      }}
                    />{" "}
                    {album}
                  </p>
                  <p
                    style={{
                      fontSize: 25,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    No Of Album
                  </p>
                </Panel>
              </Link>
            </Panel>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const styles = {
  padding: 50,
};
const stylesz = {
  padding: 50,
  width: "80vw",
  marginLeft: "10%",
};

export default Index;
