import React, { useState, useEffect } from "react";
import { List, Button, Drawer, Alert, Notification, Loader } from "rsuite";
import { useHistory } from "react-router-dom";
import { Panel, PanelGroup, Modal } from "rsuite";
import axios from "axios";
import BASE_URL from "../../constants";
import CKEditor from "ckeditor4-react";
import DatePickerx from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./info.css";

const Index = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [projectdata, setProjectData] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [newdata, setNewData] = useState({});

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date());
  const [organizers, setOrganizers] = useState("");
  const [ticketStores, setTicketStores] = useState("");
  const [locationMap, setLocationMap] = useState("");
  const [location, setLocation] = useState("");
  // Sponsor data
  const [sponsorimage, setSponsorImage] = useState("");
  const [sponsorweblink, setSponsorWeblink] = useState("");
  const [sponsorname, setSponsorName] = useState("");
  const [sponsordrawer, setSponsorDrawer] = useState(false);
  const [sponsornewdata, setSponsorNewdata] = useState([]);
  const [url, setUrl] = useState("");

  useEffect(() => {
    axios.get(`${BASE_URL}/all_events`).then((response) => {
      console.log(response.data.all_events);
      setProjectData(response.data.all_events);
    });

    if (url) {
      const requestOptionsx = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          eventid: newdata._id,
          sponsorname: sponsorname,
          sponsor_img: url,
          sponsor_url: sponsorweblink,
        }),
      };

      fetch(`${BASE_URL}/new_sponsor`, requestOptionsx)
        .then((res) => res.json())
        .then((data) => {
          if (data.err) {
            Alert.danger("Some error occured please check your data");
          } else {
            Alert.success("Event Sponsors added Successfully");
            setSponsorDrawer(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [url]);

  function handleClick() {
    history.push("/addevent", newdata);
  }

  const postDetaileSponsor = () => {
    const data = new FormData();
    data.append("file", sponsorimage);
    data.append("upload_preset", "employeeapp");
    data.append("cloud_name", "day2qlngn");
    fetch("https://api.cloudinary.com/v1_1/day2qlngn/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setUrl(data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProject = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: newdata._id,
        title: title,
        description: description,
        date: date,
        organizers: organizers,
        location: location,
        ticketStores: ticketStores,
        locationMap: locationMap,
      }),
    };

    fetch(`${BASE_URL}/updateevent`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.danger("Some error occured please check your data");
        } else {
          Alert.success("Updated Event Successfully");
          setShow(false);
          // history.push("/projects");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = (postId) => {
    fetch(`${BASE_URL}/deleteevent/${postId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        setProjectData(newData);
        setOpenModal(false);
      });
  };

  function close() {
    setShow(false);
  }
  // For main drawer to edit data
  function toggleDrawer() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShow(true);
    }, 3000);
  }

  // /For sponsor drawer/

  function closesponsordrawer() {
    setSponsorDrawer(false);
  }
  function toggleDrawerSponsor() {
    setSponsorDrawer(true);
  }

  function closeModal() {
    setOpenModal(false);
  }
  function openModal() {
    setOpenModal(true);
  }

  return (
    <div>
      {loading ? (
        <Loader
          style={{ marginLeft: "40vw", marginTop: "30vh" }}
          size="lg"
          content="We are rendering data please wait"
        />
      ) : (
        <div
          style={{
            padding: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="green"
            style={{ marginLeft: 60, padding: 15 }}
            onClick={handleClick}
          >
            + Add New Event{" "}
          </Button>

          <div
            style={{
              display: "flex",
              margin: 10,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {projectdata.map((item, index) => (
              <div
                style={{ flexDirection: "row-gap", margin: 10, fontSize: 13 }}
              >
                <Panel
                  shaded
                  style={{
                    fontSize: 20,
                    width: 300,
                    borderTop: "4px solid orange",
                  }}
                  key={index}
                  index={index}
                >
                  {item.ourevent ? (
                    <p
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Our Event
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: "orange",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      Others Event
                    </p>
                  )}
                  <p
                    style={{
                      margin: 4,
                      fontSize: 13,
                      fontWeight: 900,
                      color: "black",
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                    style={{
                      margin: 4,
                      marginBottom: 8,
                      fontSize: 13,
                      color: "gray",
                    }}
                  >
                    {item.date}
                  </p>
                  <img
                    src={item.cover_image}
                    height="50"
                    width="80"
                    style={{ marginRight: 30, borderRadius: 3 }}
                  />
                  <Button
                    color="blue"
                    onClick={() => {
                      toggleDrawer();
                      setNewData({
                        _id: item._id,
                        title: item.title,
                        description: item.description,
                        date: item.date,
                        organizers: item.organizers,
                        location: item.location,
                        ticketStores: item.ticketStores,
                        locationMap: item.locationMap,
                        cover_image: item.cover_image,
                        time: item.time,
                        sponsorsnew: item.sponsorsnew,
                      });
                      setTitle(item.title);
                      setDescription(item.description);
                      setDate(item.date);
                      setOrganizers(item.organizers);
                      setLocation(item.location);
                      setLocationMap(item.locationMap);
                      setTicketStores(item.ticketStores);
                      setSponsorNewdata(item.sponsorsnew);
                    }}
                  >
                    View{" "}
                  </Button>{" "}
                  <Button
                    color="red"
                    onClick={() => {
                      openModal();
                      setNewData({
                        _id: item._id,
                      });
                    }}
                  >
                    Delete{" "}
                  </Button>{" "}
                  <Button
                    style={{ marginTop: 10, width: "100%" }}
                    color="green"
                    onClick={() => {
                      setSponsorDrawer(true);
                      setNewData({
                        _id: item._id,
                      });
                    }}
                  >
                    Add Sponsor{" "}
                  </Button>{" "}
                  <br />
                  <p
                    style={{
                      marginTop: 8,
                      marginBottom: 1,
                      fontSize: 13,
                      color: "white",
                      backgroundColor: "#b993d6",
                      borderRadius: 5,
                      padding: 5,
                      borderLeft: "8px solid black",
                    }}
                  >
                    <b>Created at: </b>
                    {item.createdAt}
                    <br />
                    <b>Updated at: </b>
                    {item.updatedAt}
                  </p>
                  <br />
                </Panel>

                {/* MODAL CONTENT */}
                <Modal backdrop={true} show={openmodal} onHide={closeModal}>
                  <Modal.Header>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure want to delete this Event ?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        deletePost(newdata._id);
                      }}
                    >
                      Ok
                    </Button>
                    <Button onClick={closeModal} appearance="subtle">
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* DRAWER CONTENT */}

                <Drawer
                  show={show}
                  onHide={close}
                  placement="bottom"
                  style={{ height: "100vh" }}
                >
                  <Drawer.Header>
                    <Drawer.Title style={{ color: "red", fontSize: 23 }}>
                      {newdata.title} Event Full View
                    </Drawer.Title>
                  </Drawer.Header>
                  <Drawer.Body
                    style={{
                      padding: 30,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Panel
                      style={{
                        fontSize: 20,
                        width: "100%",
                        marginRight: 10,
                        scrollBehavior: "smooth",
                        overflowY: "scroll",
                      }}
                    >
                      <h5 style={{ marginBottom: 10, color: "red" }}>
                        Event Details
                      </h5>
                      <br />
                      <label>Event Id :</label>
                      <p>{newdata._id}</p>
                      <br />
                      <label>Event Title :</label>
                      <p>{newdata.title}</p>
                      <br />
                      <label>Event Date :</label>
                      <p>{newdata.date}</p>
                      <br />
                      <label>Event Time :</label>
                      <p>{newdata.time}</p>
                      <br />
                      <label>Event Location :</label>
                      <p>{newdata.location}</p>
                      <br />
                      <label>Event Image :</label>
                      <br />
                      <img
                        src={newdata.cover_image}
                        style={{ width: "100%", height: 340 }}
                      />
                      <br /> <br />
                      <label>Event Details Blog :</label>
                      <br />
                      <div
                        className="descriptionView"
                        dangerouslySetInnerHTML={{
                          __html: newdata.description,
                        }}
                      />
                      <br />
                      <label>Event ticket store details :</label>
                      <br />
                      <div
                        className="descriptionView"
                        dangerouslySetInnerHTML={{
                          __html: newdata.ticketStores,
                        }}
                      />
                      <br />
                      <label>Event organizers details :</label>
                      <br />
                      <div
                        className="descriptionView"
                        dangerouslySetInnerHTML={{ __html: newdata.organizers }}
                      />
                      <br />
                      <label>Sponsor Details :</label>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {!sponsornewdata.length ? (
                          <p style={{ color: "red" }}>No sponsors found</p>
                        ) : (
                          sponsornewdata.map((subitem) => (
                            <div
                              style={{
                                flexWrap: "wrap",
                                marginRight: 10,
                                flexDirection: "column",
                                fontSize: 10,
                              }}
                            >
                              <img
                                src={subitem.sponsor_img}
                                style={{ width: 50, height: 50 }}
                              />
                              <p>{subitem.sponsorname}</p>
                            </div>
                          ))
                        )}

                        {/* {} */}
                      </div>
                    </Panel>

                    <Panel
                      shaded
                      style={{
                        fontSize: 20,
                        width: "100%",
                        scrollBehavior: "smooth",
                        overflowY: "scroll",
                      }}
                    >
                      <h5
                        style={{
                          marginBottom: 10,
                          marginTop: 10,
                          color: "red",
                        }}
                      >
                        Edit Event Details
                      </h5>
                      <br />
                      <label>
                        Event Title
                        <input
                          style={{ width: 700 }}
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="enter title "
                        />
                      </label>
                      <br />
                      <div className="form-group">
                        <label>Event Date </label>
                        <br />
                        <DatePickerx
                          style={{ width: 700 }}
                          selected={new Date()}
                          onChange={(date) => setDate(date)}
                          dateFormat="MMMM d, yyyy"
                          className="form-control"
                        />
                      </div>

                      <label>
                        Event Location
                        <input
                          style={{ width: 700, fontSize: 14 }}
                          type="text"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          placeholder="enter Location"
                        />
                      </label>
                      <br />

                      <label>
                        Event Location Google Map
                        <input
                          style={{ width: 700, fontSize: 14 }}
                          type="text"
                          value={locationMap}
                          onChange={(e) => setLocationMap(e.target.value)}
                          placeholder="enter Location (Google Map)"
                        />
                      </label>
                      <br />
                      <label>Event Description</label>
                      <CKEditor
                        data={description}
                        onChange={(evt) => {
                          setDescription(evt.editor.getData());
                        }}
                      />

                      <br />
                      <label>Event Ticket Stores</label>
                      <CKEditor
                        data={ticketStores}
                        onChange={(evt) => {
                          setTicketStores(evt.editor.getData());
                        }}
                      />
                      <br />
                      <label>Event Organizers</label>
                      <CKEditor
                        data={organizers}
                        onChange={(evt) => {
                          setOrganizers(evt.editor.getData());
                        }}
                      />
                    </Panel>
                  </Drawer.Body>
                  <Drawer.Footer>
                    <Button
                      onClick={() => {
                        updateProject();
                      }}
                      appearance="primary"
                      color="blue"
                    >
                      Update
                    </Button>
                    <Button onClick={close} appearance="primary" color="red">
                      Close
                    </Button>
                  </Drawer.Footer>
                </Drawer>
                {/* for addd sponsors */}
                <Drawer
                  size="xs"
                  placement="right"
                  show={sponsordrawer}
                  onHide={closesponsordrawer}
                >
                  <Drawer.Header>
                    <Drawer.Title>
                      Add New sponsor to the event {newdata.title}
                    </Drawer.Title>
                  </Drawer.Header>
                  <Drawer.Body>
                    <h5 style={{ color: "#ee0979", fontSize: 13 }}>
                      {" "}
                      Add New sponsor one by one to Event : {newdata.title}
                    </h5>
                    <br />
                    <label>
                      Event Sponsor name
                      <input
                        type="text"
                        value={sponsorname}
                        onChange={(e) => setSponsorName(e.target.value)}
                        placeholder="enter sponsor name "
                      />
                    </label>
                    <br /> <br />
                    <div className="form-group">
                      <label>Upload Sponsor Logo</label>
                      <input
                        style={{ padding: 10, marginTop: -2 }}
                        type="file"
                        onChange={(e) => setSponsorImage(e.target.files[0])}
                      />
                    </div>
                    <label>
                      Event Sponsor weblink
                      <input
                        type="text"
                        value={sponsorweblink}
                        onChange={(e) => setSponsorWeblink(e.target.value)}
                        placeholder="enter sponsor name "
                      />
                    </label>
                    <br />
                    <br />
                    <p style={{ color: "orange", fontSize: 13 }}>
                      <b>*** SUMMARY</b>
                      <br /> * please add sponsor image sponsor name and sponsor
                      web redirect link one by one).
                      <br />
                    </p>
                  </Drawer.Body>
                  <Drawer.Footer>
                    <Button
                      onClick={() => postDetaileSponsor()}
                      appearance="primary"
                    >
                      Add Sponsor
                    </Button>
                    <Button onClick={closesponsordrawer} appearance="subtle">
                      Cancel
                    </Button>
                  </Drawer.Footer>
                </Drawer>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
