import React, { useState, useEffect } from "react";
import { List, Button, Drawer, Alert, Notification } from "rsuite";
import { useHistory } from "react-router-dom";
import { Panel, PanelGroup, Modal } from "rsuite";
import axios from "axios";
import BASE_URL from "../../constants";
import CKEditor from "ckeditor4-react";
import DatePickerx from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Index = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [projectdata, setProjectData] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [newdata, setNewData] = useState({});
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    axios.get(`${BASE_URL}/all_projects`).then((response) => {
      console.log(response.data.all_projects);
      setProjectData(response.data.all_projects);
    });
  });

  function handleClick() {
    history.push("/addproject");
  }

  const updateProject = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: newdata._id,
        name: name,
        description: description,
      }),
    };

    fetch(`${BASE_URL}/updateproject`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.danger("Some error occured please check your data");
        } else {
          // Notification.open("success")({
          //   title: "Success Message",
          //   description: <p>Updated project Successfully</p>,
          // });
          Alert.success("Updated project Successfully");
          setShow(false);
          // history.push("/projects");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = (postId) => {
    fetch(`${BASE_URL}/deleteproject/${postId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        setProjectData(newData);
        setOpenModal(false);
      });
  };

  function close() {
    setShow(false);
  }
  function toggleDrawer() {
    setShow(true);
  }

  function closeModal() {
    setOpenModal(false);
  }
  function openModal() {
    setOpenModal(true);
  }

  return (
    <div
      style={{
        padding: 100,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        color="green"
        style={{ marginLeft: 60, padding: 15 }}
        onClick={handleClick}
      >
        + Add New Project{" "}
      </Button>

      <div
        style={{
          display: "flex",
          margin: 10,
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {projectdata.map((item, index) => (
          <div style={{ flexDirection: "row-gap", margin: 10 }}>
            <Panel
              shaded
              style={{
                fontSize: 13,
                width: 300,
                borderTop: "4px solid red",
              }}
              key={index}
              index={index}
            >
              {/* <Link
                style={{ textDecoration: "none" }}
                to={`/projectsview/${item._id}`}
              >
                View
              </Link> */}
              {/* <p onClick={fullview(item._id)}>hey iew</p> */}
              <p
                style={{
                  margin: 4,
                  fontSize: 12,
                  fontWeight: 900,
                  color: "black",
                }}
              >
                {item.name}
              </p>
              <p
                style={{
                  margin: 4,
                  marginBottom: 8,
                  fontSize: 13,
                  color: "gray",
                }}
              >
                {item.date}
              </p>
              <img
                src={item.profileImg}
                height="50"
                width="80"
                style={{ marginRight: 30, borderRadius: 3 }}
              />
              <Button
                color="blue"
                onClick={() => {
                  toggleDrawer();
                  setNewData({
                    _id: item._id,
                    name: item.name,
                    description: item.description,
                    profileImg: item.profileImg,
                  });
                  setName(item.name);
                  setDescription(item.description);
                }}
              >
                View{" "}
              </Button>{" "}
              <Button
                color="red"
                onClick={() => {
                  openModal();
                  setNewData({
                    _id: item._id,
                  });
                }}
              >
                Delete{" "}
              </Button>{" "}
              <br />
              <p
                style={{
                  marginTop: 8,
                  marginBottom: 1,
                  fontSize: 13,
                  color: "white",
                  backgroundColor: "blue",
                  borderRadius: 5,
                  padding: 5,
                }}
              >
                <b>Created at: </b>
                {item.createdAt}
                <br />
                <b>Updated at: </b>
                {item.updatedAt}
              </p>
              <br />
            </Panel>

            {/* MODAL CONTENT */}
            <Modal backdrop={true} show={openmodal} onHide={closeModal}>
              <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure want to delete this project ?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="primary"
                  onClick={() => {
                    deletePost(newdata._id);
                  }}
                >
                  Ok
                </Button>
                <Button onClick={closeModal} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            {/* DRAWER CONTENT */}
            <Drawer
              show={show}
              onHide={close}
              placement="right"
              style={{ width: "90%" }}
            >
              <Drawer.Header>
                <Drawer.Title style={{ color: "red", fontSize: 20 }}>
                  {newdata.name} Project Full View
                </Drawer.Title>
              </Drawer.Header>
              <Drawer.Body
                style={{ padding: 30, display: "flex", flexDirection: "row" }}
              >
                <Panel
                  shaded
                  style={{
                    fontSize: 20,
                    width: "100%",
                    marginRight: 10,
                    scrollBehavior: "smooth",
                    overflowY: "scroll",
                  }}
                >
                  <h5 style={{ marginBottom: 10, color: "red" }}>
                    Project Details
                  </h5>
                  <br />
                  <label>Project Id :</label>
                  <p>{newdata._id}</p>
                  <br />
                  <label>Project Name :</label>
                  <p>{newdata.name}</p>
                  <label>Project Image :</label>
                  <br />
                  <img
                    src={newdata.profileImg}
                    style={{ width: "100%", height: 250 }}
                  />
                  <br />
                  <label>Project Details Blog :</label>
                  <br />
                  <div
                    className="descriptionView"
                    dangerouslySetInnerHTML={{ __html: newdata.description }}
                  />
                </Panel>

                <Panel shaded style={{ fontSize: 20, width: "100%" }}>
                  <h5 style={{ marginBottom: 10, marginTop: 10, color: "red" }}>
                    Edit Project Details
                  </h5>
                  <br />
                  <label>
                    Project Title
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="enter title of project"
                    />
                  </label>
                  <br />
                  <CKEditor
                    data={newdata.description}
                    onChange={(evt) => {
                      setDescription(evt.editor.getData());
                    }}
                    // onChange={onEditorChange}
                  />
                  {/* <h3>{description}</h3>
                  <h4>{name}</h4> */}
                </Panel>
              </Drawer.Body>
              <Drawer.Footer>
                <Button
                  onClick={() => {
                    updateProject();
                  }}
                  appearance="primary"
                  color="blue"
                >
                  Update
                </Button>
                <Button onClick={close} appearance="primary" color="red">
                  Close
                </Button>
              </Drawer.Footer>
            </Drawer>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
