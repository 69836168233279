import React, { useState, useEffect } from "react";
import { Nav, Icon } from "rsuite";
import {
  Navbar,
  FlexboxGrid,
  Form,
  Panel,
  FormGroup,
  ControlLabel,
  FormControl,
  Container,
  Header,
  Content,
  Button,
  ButtonToolbar,
  Alert,
} from "rsuite";
// import { NavLink } from "react-router-dom";
import { useHistory, NavLink } from "react-router-dom";
import BASE_URL from "../../constants";
const Index = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const PostData = () => {
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) {
      Alert.danger("invalid token");
      return;
    }
    fetch(`${BASE_URL}/signin`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.error("Please Provide username and password");

          return;
        } else {
          const token = "hhasdhsiua7823647823488ghbhwff28736492634932949632";
          localStorage.setItem("jwt", token);
          Alert.success("Sign in succesfully");
          window.location.reload();
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const instance = (
    <div className="show-fake-browser login-page">
      <Container>
        <Header>
          <Navbar appearance="inverse">
            <Navbar.Header>
              <a className="navbar-brand logo">ConcernSrilanka Admin</a>
            </Navbar.Header>
          </Navbar>
        </Header>
        <Content>
          <br />
          <FlexboxGrid justify="center" style={{ marginTop: 50 }}>
            <FlexboxGrid.Item colspan={12}>
              <Panel
                header={
                  <h3 style={{ color: "#3498ff", padding: 10 }}>
                    Login Here !
                  </h3>
                }
                bordered
              >
                <Form fluid>
                  <FormGroup>
                    <ControlLabel>Username as email address</ControlLabel>
                    <input
                      style={{
                        padding: 20,
                        color: "black",
                        fontSize: 17,
                        width: "100%",
                      }}
                      placeholder="Enter your email/ username"
                      value={email}
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Password</ControlLabel>
                    <input
                      style={{
                        padding: 20,
                        color: "black",
                        fontSize: 17,
                        width: "100%",
                      }}
                      placeholder="Enter Password"
                      value={password}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ButtonToolbar>
                      <Button
                        size="lg"
                        appearance="primary"
                        onClick={PostData}
                        style={{ width: "100%" }}
                      >
                        Sign in
                      </Button>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
    </div>
  );

  return <div>{instance}</div>;
};

export default Index;
