import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Drawer,
  Alert,
  Notification,
  Loader,
  SelectPicker,
  Modal,
} from "rsuite";
import { useHistory } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../constants";
import "react-datepicker/dist/react-datepicker.css";

const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [projectdata, setProjectData] = useState([]);
  const [tickerdata, setTickerdata] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [newdata, setNewData] = useState({});
  const [newdata2, setNewData2] = useState({});

  // Sponsor data

  const [sponsorname, setSponsorName] = useState("");
  const [sponsordrawer, setSponsorDrawer] = useState(false);
  const [sponsordrawer1, setSponsordrawer1] = useState(false);
  const [tickerdrawer, setTickerDrawer] = useState(false);

  // New data
  const [newname, setNewname] = useState("");
  const [updatedrawer1, setUpdatedrawer1] = useState(false);
  const [updatedrawer2, setUpdatedrawer2] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    RefreshData();
  }, []);

  const RefreshData = () => {
    axios.get(`${BASE_URL}/all_ticker`).then((response) => {
      console.log(response.data.all_ticker);
      setProjectData(response.data.all_ticker);
    });

    axios.get(`${BASE_URL}/all_ticker2`).then((response) => {
      console.log(response.data.all_ticker);
      setTickerdata(response.data.all_ticker);
    });
  };

  // ticker 1
  const postDetaileSponsor = () => {
    const requestOptionsx = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: sponsorname,
      }),
    };

    fetch(`${BASE_URL}/newticker`, requestOptionsx)
      .then((res) => res.json())
      .then((data) => {
        if (data.err) {
          Alert.danger("Some error occured please check your data");
        } else {
          Alert.success("ticker added Successfully");
          setSponsorDrawer(false);
          RefreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ticker 2 add
  const addTicker2 = () => {
    const requestOptionsx = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: sponsorname,
      }),
    };

    fetch(`${BASE_URL}/newticker2`, requestOptionsx)
      .then((res) => res.json())
      .then((data) => {
        if (data.err) {
          Alert.danger("Some error occured please check your data");
        } else {
          Alert.success("ticker added Successfully");
          setSponsordrawer1(false);
          RefreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = (tickerId) => {
    fetch(`${BASE_URL}/deleteticker/${tickerId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        setProjectData(newData);
        setDeleteModal(false);
        RefreshData();
      });
  };

  const deletePost2 = (tickerId) => {
    fetch(`${BASE_URL}/deleteticker2/${tickerId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        setProjectData(newData);
        setOpenModal(false);
        RefreshData();
      });
  };

  // /For sponsor drawer/

  function closesponsordrawer() {
    setSponsorDrawer(false);
  }

  function openTickerDrawer() {
    setTickerDrawer(true);
  }

  function closeupdatedrawer1() {
    setUpdatedrawer1(false);
  }

  // /For sponsor drawer/

  function closeTickerDrawer() {
    setTickerDrawer(false);
  }

  function closeModal() {
    setOpenModal(false);
  }
  function openModal() {
    setOpenModal(true);
  }

  const updateProject = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: newdata._id,
        name: newname,
      }),
    };

    fetch(`${BASE_URL}/updateticker`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.danger("Some error occured please check your data");
        } else {
          setUpdatedrawer1(false);
          RefreshData();
          Alert.success("Sucessfully Updated  !!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProject2 = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: newdata._id,
        name: newname,
      }),
    };

    fetch(`${BASE_URL}/updateticker2`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.danger("Some error occured please check your data");
        } else {
          setUpdatedrawer2(false);
          RefreshData();
          Alert.success("Sucessfully Updated  !!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader
          style={{ marginLeft: "40vw", marginTop: "30vh" }}
          size="lg"
          content="We are rendering data please wait"
        />
      ) : (
        <div
          style={{
            padding: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="green"
            style={{ marginLeft: 60, padding: 15 }}
            onClick={() => {
              setSponsorDrawer(true);
            }}
          >
            + Add New Ticker Scroll
          </Button>

          <div
            style={{
              display: "flex",
              margin: 10,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <List style={{ width: "90%", marginTop: 30 }}>
              {projectdata.map((item, index) => (
                <div>
                  <List.Item
                    key={item._id}
                    style={{
                      display: "flex",
                      margin: 10,
                      fontSize: 13,
                    }}
                  >
                    <div style={{ flexDirection: "row" }}>
                      <p style={{ color: "green", width: "80%" }}>
                        {item.name}
                      </p>
                      <div>
                        <Button
                          color="blue"
                          onClick={() => {
                            setUpdatedrawer1(true);
                            setNewData({
                              _id: item._id,
                            });
                            setNewname(item.name);
                          }}
                        >
                          Update
                        </Button>{" "}
                        <Button
                          color="red"
                          onClick={() => {
                            setDeleteModal(true);
                            setNewData({
                              _id: item._id,
                            });
                          }}
                        >
                          Delete
                        </Button>{" "}
                      </div>
                    </div>
                  </List.Item>
                  <br />

                  {/* MODAL CONTENT */}
                  <Modal backdrop={true} show={openmodal} onHide={closeModal}>
                    <Modal.Header>
                      <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure want to delete this Ticker 1 ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          deletePost(newdata2._id);
                        }}
                      >
                        Ok
                      </Button>
                      <Button onClick={closeModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* DRAWER CONTENT */}

                  {/* MODAL CONTENT */}
                  <Modal
                    backdrop={true}
                    show={deleteModal}
                    onHide={() => setDeleteModal(false)}
                  >
                    <Modal.Header>
                      <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure want to delete this Ticker 1 ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          deletePost(newdata._id);
                        }}
                      >
                        Ok
                      </Button>
                      <Button onClick={closeModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              ))}
            </List>

            <Drawer
              size="xs"
              placement="right"
              show={sponsordrawer}
              onHide={closesponsordrawer}
            >
              <Drawer.Header>
                <Drawer.Title>
                  Add New Ticker data called as scrolling text
                </Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <br /> <br />
                <div className="form-group">
                  <label>
                    Scrolling Text
                    <input
                      style={{ padding: 30, marginTop: 10 }}
                      type="text"
                      value={sponsorname}
                      onChange={(e) => setSponsorName(e.target.value)}
                      placeholder="enter Scrolling Text "
                    />
                  </label>
                </div>
                <br />
                <p style={{ color: "orange", fontSize: 13 }}>
                  <b>*** SUMMARY</b>
                  <br /> * please add Ticker one by one).
                  <br />
                </p>
              </Drawer.Body>
              <Drawer.Footer>
                <Button
                  onClick={() => postDetaileSponsor()}
                  appearance="primary"
                >
                  Add Ticker
                </Button>
                <Button onClick={closesponsordrawer} appearance="subtle">
                  Cancel
                </Button>
              </Drawer.Footer>
            </Drawer>

            <Drawer
              size="xs"
              placement="right"
              show={sponsordrawer1}
              onHide={() => setSponsordrawer1(false)}
            >
              <Drawer.Header>
                <Drawer.Title>
                  Add New Ticker data called as scrolling text
                </Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <br /> <br />
                <div className="form-group">
                  <label>
                    Scrolling Text
                    <input
                      style={{ padding: 30, marginTop: 10 }}
                      type="text"
                      value={sponsorname}
                      onChange={(e) => setSponsorName(e.target.value)}
                      placeholder="enter Scrolling Text "
                    />
                  </label>
                </div>
                <br />
                <p style={{ color: "orange", fontSize: 13 }}>
                  <b>*** SUMMARY</b>
                  <br /> * please add Ticker one by one).
                  <br />
                </p>
              </Drawer.Body>
              <Drawer.Footer>
                <Button onClick={() => addTicker2()} appearance="primary">
                  Add Ticker 2
                </Button>
                <Button onClick={closesponsordrawer} appearance="subtle">
                  Cancel
                </Button>
              </Drawer.Footer>
            </Drawer>

            <Drawer
              size="xs"
              placement="right"
              show={updatedrawer1}
              onHide={closeupdatedrawer1}
            >
              <Drawer.Header>
                <Drawer.Title>Update scrolling ticker</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <br /> <br />
                <div className="form-group">
                  <label>
                    Scrolling Text
                    <input
                      style={{ padding: 30, marginTop: 10 }}
                      type="text"
                      value={newname}
                      onChange={(e) => setNewname(e.target.value)}
                      placeholder="enter Scrolling Text "
                    />
                  </label>
                </div>
                <br />
                <p style={{ color: "orange", fontSize: 13 }}>
                  <b>*** SUMMARY</b>
                  <br /> * please add Ticker one by one).
                  <br />
                </p>
              </Drawer.Body>
              <Drawer.Footer>
                <Button
                  color="orange"
                  onClick={() => updateProject()}
                  appearance="primary"
                >
                  update Ticker
                </Button>
                <Button onClick={closesponsordrawer} appearance="subtle">
                  Cancel
                </Button>
              </Drawer.Footer>
            </Drawer>

            <Drawer
              size="xs"
              placement="right"
              show={updatedrawer2}
              onHide={() => {
                setUpdatedrawer2(false);
              }}
            >
              <Drawer.Header>
                <Drawer.Title>Update scrolling ticker 2</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <br /> <br />
                <div className="form-group">
                  <label>
                    Scrolling Text
                    <input
                      style={{ padding: 30, marginTop: 10 }}
                      type="text"
                      value={newname}
                      onChange={(e) => setNewname(e.target.value)}
                      placeholder="enter Scrolling Text "
                    />
                  </label>
                </div>
                <br />
                <p style={{ color: "orange", fontSize: 13 }}>
                  <b>*** SUMMARY</b>
                  <br /> * please add Ticker one by one).
                  <br />
                </p>
              </Drawer.Body>
              <Drawer.Footer>
                <Button
                  color="orange"
                  onClick={() => updateProject2()}
                  appearance="primary"
                >
                  update Ticker
                </Button>
                <Button onClick={closesponsordrawer} appearance="subtle">
                  Cancel
                </Button>
              </Drawer.Footer>
            </Drawer>
          </div>

          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              color="violet"
              style={{ marginLeft: 60, padding: 15 }}
              onClick={() => {
                setSponsorName("");
                setSponsordrawer1(true);
              }}
            >
              + Add New Ticker Scroll 1
            </Button>

            <div
              style={{
                display: "flex",
                margin: 10,

                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <List style={{ width: "90%", marginTop: 30 }}>
                {tickerdata.map((item, index) => (
                  <div>
                    <List.Item
                      key={item._id}
                      style={{
                        display: "flex",
                        margin: 10,
                        fontSize: 13,
                      }}
                    >
                      <div style={{ flexDirection: "row" }}>
                        <p style={{ color: "violet", width: "80%" }}>
                          {item.name}
                        </p>
                        <div style={{}}>
                          <Button
                            color="blue"
                            onClick={() => {
                              setUpdatedrawer2(true);
                              setNewData({
                                _id: item._id,
                              });
                              setNewname(item.name);
                            }}
                          >
                            Update
                          </Button>{" "}
                          <Button
                            color="red"
                            onClick={() => {
                              openModal();
                              setNewData({
                                _id: item._id,
                              });
                            }}
                          >
                            Delete
                          </Button>{" "}
                        </div>
                      </div>
                    </List.Item>
                    <br />

                    {/* MODAL CONTENT */}
                    <Modal backdrop={true} show={openmodal} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure want to delete this Ticker ?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          appearance="primary"
                          onClick={() => {
                            deletePost2(newdata._id);
                          }}
                        >
                          Ok
                        </Button>
                        <Button onClick={closeModal} appearance="subtle">
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* DRAWER CONTENT */}

                    <Drawer
                      size="xs"
                      placement="right"
                      show={tickerdrawer}
                      onHide={closeTickerDrawer}
                    >
                      <Drawer.Header>
                        <Drawer.Title>
                          Add New Ticker data called as scrolling text 1
                        </Drawer.Title>
                      </Drawer.Header>
                      <Drawer.Body>
                        <br /> <br />
                        <div className="form-group">
                          <label>
                            Scrolling Text
                            <input
                              style={{ padding: 30, marginTop: 10 }}
                              type="text"
                              value={sponsorname}
                              onChange={(e) => setSponsorName(e.target.value)}
                              placeholder="enter Scrolling Text "
                            />
                          </label>
                        </div>
                        <br />
                        <p style={{ color: "orange", fontSize: 13 }}>
                          <b>*** SUMMARY</b>
                          <br /> * please add Ticker one by one).
                          <br />
                        </p>
                      </Drawer.Body>
                      <Drawer.Footer>
                        <Button
                          onClick={() => postDetaileSponsor()}
                          appearance="primary"
                        >
                          Add Ticker
                        </Button>
                        <Button
                          onClick={closesponsordrawer}
                          appearance="subtle"
                        >
                          Cancel
                        </Button>
                      </Drawer.Footer>
                    </Drawer>
                  </div>
                ))}
              </List>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
