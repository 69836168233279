import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Drawer,
  Alert,
  Notification,
  Loader,
  SelectPicker,
  Toggle,
} from "rsuite";
import { useHistory } from "react-router-dom";
import { Panel, PanelGroup, Modal } from "rsuite";
import axios from "axios";
import BASE_URL from "../../constants";
import CKEditor from "ckeditor4-react";
import DatePickerx from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Index = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [projectdata, setProjectData] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [newdata, setNewData] = useState({});

  // Sponsor data
  const [sponsorimage, setSponsorImage] = useState("");
  const [sponsorweblink, setSponsorWeblink] = useState("");
  const [sponsordescription, setSponsorDescription] = useState("");
  const [sponsorname, setSponsorName] = useState("");
  const [isourevent, setIsourEvent] = useState(true);
  const [sponsordrawer, setSponsorDrawer] = useState(false);
  const [newisourevent, setNewisourEvent] = useState(false);
  const [url, setUrl] = useState("");

  // Drawer details
  const [updateDrawer, setUpdateDrawer] = useState(false);

  // Switch toggle
  const [switchi, setSwitchi] = useState(false);

  useEffect(() => {
    if (!switchi) {
      axios.get(`${BASE_URL}/event_sponsors`).then((response) => {
        console.log(response.data.eventsponsors);
        setProjectData(response.data.eventsponsors);
      });
    }

    if (url) {
      const requestOptionsx = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: sponsorname,
          image: url,
          officiallink: sponsorweblink,
          iseventsponsor: isourevent,
          description: sponsordescription,
        }),
      };

      fetch(`${BASE_URL}/newsponsor`, requestOptionsx)
        .then((res) => res.json())
        .then((data) => {
          if (data.err) {
            Alert.danger("Some error occured please check your data");
          } else {
            Alert.success("Sponsors added Successfully");
            setSponsorDrawer(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [url]);

  const changeStatevaluefilter = () => {
    if (!switchi) {
      axios.get(`${BASE_URL}/other_sponsors`).then((response) => {
        console.log(response.data.othersponsors);
        setProjectData(response.data.othersponsors);
        setNewisourEvent(true);
      });
    } else {
      axios.get(`${BASE_URL}/event_sponsors`).then((response) => {
        console.log(response.data.eventsponsors);
        setProjectData(response.data.eventsponsors);
        setNewisourEvent(false);
      });
    }
  };

  const postDetaileSponsor = () => {
    const data = new FormData();
    setLoading(true);
    data.append("file", sponsorimage);
    data.append("upload_preset", "employeeapp");
    data.append("cloud_name", "day2qlngn");
    fetch("https://api.cloudinary.com/v1_1/day2qlngn/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setUrl(data.url);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProject = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: newdata._id,
        name: sponsorname,
        officiallink: sponsorweblink,
        description: sponsordescription,
      }),
    };

    fetch(`${BASE_URL}/updatesponsor`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          Alert.danger("Some error occured please check your data");
        } else {
          setLoading(false);
          setUpdateDrawer(false);
          changeStatevaluefilter();
          setSwitchi(!switchi);
          Alert.success("Sucessfully Updated  !!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = (sponsorId) => {
    fetch(`${BASE_URL}/deletesponsor/${sponsorId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        axios.get(`${BASE_URL}/event_sponsors`).then((response) => {
          console.log(response.data.eventsponsors);
          setProjectData(response.data.eventsponsors);
        });
        setProjectData(newData);
        setOpenModal(false);
      });
  };

  function openDrawer() {
    setSponsorDrawer(true);
  }

  function closesponsordrawer() {
    setSponsorDrawer(false);
  }

  function updateclosesponsordrawer() {
    setUpdateDrawer(false);
  }

  function closeModal() {
    setOpenModal(false);
  }
  function openModal() {
    setOpenModal(true);
  }

  return (
    <div>
      {loading ? (
        <Loader
          style={{ marginLeft: "40vw", marginTop: "30vh" }}
          size="lg"
          content="We are rendering data please wait"
        />
      ) : (
        <div
          style={{
            padding: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              color="green"
              style={{ marginLeft: 60, padding: 15 }}
              onClick={openDrawer}
            >
              + Add New Sponsor{" "}
            </Button>
            <p
              style={{
                marginLeft: 10,
                marginRight: 10,
                color: "green",
                fontWeight: "bold",
              }}
            >
              || Switch to project Sponsor
            </p>
            <Toggle
              size="lg"
              checked={switchi}
              onChange={() => {
                setSwitchi(!switchi);
                changeStatevaluefilter();
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              margin: 10,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {projectdata.map((item, index) => (
              <div
                style={{ flexDirection: "row-gap", margin: 10, fontSize: 13 }}
              >
                <Panel
                  shaded
                  style={{
                    fontSize: 20,
                    width: 300,
                    borderTop: "4px solid #243b55",
                  }}
                  key={index}
                  index={index}
                >
                  {item.iseventsponsor ? (
                    <p
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      EVENT SPONSOR
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      PROJECT SPONSOR
                    </p>
                  )}
                  <p style={{ margin: 4, fontSize: 20, color: "black" }}>
                    {item.name}
                  </p>
                  <img
                    src={item.image}
                    height="50"
                    width="80"
                    style={{ marginRight: 30, borderRadius: 3 }}
                  />
                  <Button
                    color="blue"
                    onClick={() => {
                      setUpdateDrawer(true);
                      setNewData({
                        _id: item._id,
                        name: item.name,
                        officiallink: item.officiallink,
                        description: item.description,
                      });
                      setSponsorName(item.name);
                      setSponsorWeblink(item.officiallink);
                      setSponsorDescription(item.description);
                      setNewisourEvent(item.iseventsponsor);
                    }}
                  >
                    update{" "}
                  </Button>{" "}
                  <Button
                    color="red"
                    onClick={() => {
                      openModal();
                      setNewData({
                        _id: item._id,
                      });
                    }}
                  >
                    Delete
                  </Button>{" "}
                  <br />
                  <p>{item.officiallink}</p>
                  <p
                    style={{
                      marginTop: 8,
                      marginBottom: 1,
                      fontSize: 13,
                      color: "white",
                      backgroundColor: "#24c6dc",
                      borderRadius: 5,
                      padding: 5,
                      borderLeft: "8px solid black",
                    }}
                  >
                    <b>Created at: </b>
                    {item.createdAt}
                    <br />
                    <b>Updated at: </b>
                    {item.updatedAt}
                  </p>
                  {newisourevent ? (
                    <p
                      style={{
                        marginTop: 8,
                        marginBottom: 1,
                        fontSize: 13,
                        color: "white",
                        backgroundColor: "#eb3349",
                        borderRadius: 5,
                        padding: 5,
                        borderLeft: "8px solid black",
                      }}
                    >
                      <b>Description : </b>
                      {item.description}
                      <br />
                    </p>
                  ) : (
                    <></>
                  )}
                  <br />
                </Panel>

                {/* MODAL CONTENT */}
                <Modal backdrop={true} show={openmodal} onHide={closeModal}>
                  <Modal.Header>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure want to delete this Sponsor ?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        deletePost(newdata._id);
                      }}
                    >
                      Ok
                    </Button>
                    <Button onClick={closeModal} appearance="subtle">
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* DRAWER CONTENT */}

                <Drawer
                  size="xs"
                  placement="right"
                  show={sponsordrawer}
                  onHide={closesponsordrawer}
                >
                  <Drawer.Header>
                    <Drawer.Title>Add New sponsor</Drawer.Title>
                  </Drawer.Header>
                  <Drawer.Body>
                    <h5 style={{ color: "#ee0979", fontSize: 13 }}>
                      {" "}
                      Add New sponsor one by one
                    </h5>
                    <br />
                    <label>
                      Event Sponsor name
                      <input
                        type="text"
                        value={newdata.sponsorname}
                        onChange={(e) => setSponsorName(e.target.value)}
                        placeholder="enter sponsor name "
                      />
                    </label>
                    <br /> <br />
                    <div className="form-group">
                      <label>Upload Sponsor Logo</label>
                      <input
                        style={{ padding: 10, marginTop: -2 }}
                        type="file"
                        onChange={(e) => setSponsorImage(e.target.files[0])}
                      />
                    </div>
                    <label>
                      Event Sponsor weblink
                      <input
                        type="text"
                        value={newdata.sponsorweblink}
                        onChange={(e) => setSponsorWeblink(e.target.value)}
                        placeholder="enter sponsor link "
                      />
                    </label>
                    <label>Check our event or others event</label>
                    <br />
                    <SelectPicker
                      data={[
                        {
                          label: "Event Sponsor",
                          value: true,
                        },
                        {
                          label: "Project Sponsor",
                          value: false,
                        },
                      ]}
                      value={isourevent}
                      onChange={(evt) => setIsourEvent(evt)}
                      style={{ width: 224 }}
                    />
                    <br /> <br />
                    {!isourevent ? (
                      <label>
                        Some detail description
                        <input
                          style={{ padding: 40 }}
                          type="text"
                          value={newdata.sponsordescription}
                          onChange={(e) =>
                            setSponsorDescription(e.target.value)
                          }
                          placeholder="enter Some description "
                        />
                      </label>
                    ) : (
                      <></>
                    )}
                    <br />
                    <p style={{ color: "orange", fontSize: 13 }}>
                      <b>*** SUMMARY</b>
                      <br /> * please add sponsor image sponsor name and sponsor
                      web redirect link one by one).
                      <br />
                    </p>
                  </Drawer.Body>
                  <Drawer.Footer>
                    <Button
                      onClick={() => postDetaileSponsor()}
                      appearance="primary"
                    >
                      Add Sponsor
                    </Button>
                    <Button onClick={closesponsordrawer} appearance="subtle">
                      Cancel
                    </Button>
                  </Drawer.Footer>
                </Drawer>

                {/* DRAWER CONTENT */}

                <Drawer
                  size="xs"
                  placement="right"
                  show={updateDrawer}
                  onHide={updateclosesponsordrawer}
                >
                  <Drawer.Header>
                    <Drawer.Title>Update sponsor</Drawer.Title>
                  </Drawer.Header>
                  <Drawer.Body>
                    <h5 style={{ color: "#ee0979", fontSize: 13 }}>
                      {" "}
                      Update Sponsor
                    </h5>
                    <br />
                    <label>
                      Event Sponsor name
                      <input
                        type="text"
                        value={sponsorname}
                        onChange={(e) => setSponsorName(e.target.value)}
                        placeholder="enter sponsor name "
                      />
                    </label>
                    <br /> <br />
                    <label>
                      Event Sponsor weblink
                      <input
                        type="text"
                        value={sponsorweblink}
                        onChange={(e) => setSponsorWeblink(e.target.value)}
                        placeholder="enter sponsor link "
                      />
                    </label>
                    <br />
                    {!newisourevent ? (
                      <label>
                        Some detail description update
                        <input
                          style={{ padding: 40 }}
                          type="text"
                          value={sponsordescription}
                          onChange={(e) =>
                            setSponsorDescription(e.target.value)
                          }
                          placeholder="enter Some description "
                        />
                      </label>
                    ) : (
                      <></>
                    )}
                    <br />
                    <p style={{ color: "orange", fontSize: 13 }}>
                      <b>*** SUMMARY</b>
                      <br /> *Update sponsor details here).
                      <br />
                    </p>
                  </Drawer.Body>
                  <Drawer.Footer>
                    <Button
                      onClick={() => {
                        updateProject();
                      }}
                      appearance="primary"
                      color="blue"
                    >
                      Update
                    </Button>
                    <Button
                      onClick={updateclosesponsordrawer}
                      appearance="primary"
                      color="red"
                    >
                      Close
                    </Button>
                  </Drawer.Footer>
                </Drawer>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
