import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Drawer,
  Alert,
  Notification,
  Loader,
  SelectPicker,
} from "rsuite";
import { useHistory } from "react-router-dom";
import { Panel, PanelGroup, Modal } from "rsuite";
import axios from "axios";
import BASE_URL from "../../constants";
import "react-datepicker/dist/react-datepicker.css";

const Index = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [projectdata, setProjectData] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [newdata, setNewData] = useState({});

  // Sponsor data
  const [sponsorimage, setSponsorImage] = useState("");
  const [sponsorweblink, setSponsorWeblink] = useState("");
  const [sponsorname, setSponsorName] = useState("");
  const [isourevent, setIsourEvent] = useState(true);
  const [sponsordrawer, setSponsorDrawer] = useState(false);
  const [sponsornewdata, setSponsorNewdata] = useState([]);
  const [url, setUrl] = useState("");

  useEffect(() => {
    refreshData();
    if (url) {
      const requestOptionsx = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          image: url,
        }),
      };

      fetch(`${BASE_URL}/newcarousel`, requestOptionsx)
        .then((res) => res.json())
        .then((data) => {
          setLoading(true);
          if (data.err) {
            Alert.danger("Some error occured please check your data");
          } else {
            Alert.success("Sponsors added Successfully");
            setSponsorDrawer(false);
            setLoading(false);
            refreshData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [url]);

  const refreshData = () => {
    axios.get(`${BASE_URL}/all_carousel_data`).then((response) => {
      console.log(response.data.all_carousel_data);
      setProjectData(response.data.all_carousel_data);
      setLoading(false);
    });
  };

  const postDetaileSponsor = () => {
    setLoading(true);
    const data = new FormData();
    data.append("file", sponsorimage);
    data.append("upload_preset", "employeeapp");
    data.append("cloud_name", "day2qlngn");
    fetch("https://api.cloudinary.com/v1_1/day2qlngn/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setUrl(data.url);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = (carouselId) => {
    fetch(`${BASE_URL}/deletecarousel/${carouselId}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const newData = projectdata.filter((item) => {
          return item._id !== result._id;
        });
        setProjectData(newData);
        setOpenModal(false);
        refreshData();
        // history.push("/sponsors");
      });
  };

  function openDrawer() {
    setSponsorDrawer(true);
  }

  // /For sponsor drawer/

  function closesponsordrawer() {
    setSponsorDrawer(false);
  }

  function closeModal() {
    setOpenModal(false);
  }
  function openModal() {
    setOpenModal(true);
  }

  return (
    <div>
      {loading ? (
        <Loader
          style={{ marginLeft: "40vw", marginTop: "30vh" }}
          size="lg"
          content="We are rendering data please wait"
        />
      ) : (
        <div
          style={{
            padding: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="green"
            style={{ marginLeft: 60, padding: 15 }}
            onClick={openDrawer}
          >
            + Add New Carousel Image{" "}
          </Button>

          <div
            style={{
              display: "flex",
              margin: 10,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {projectdata.map((item, index) => (
              <div
                style={{ flexDirection: "row-gap", margin: 10, fontSize: 13 }}
              >
                <Panel
                  shaded
                  style={{
                    fontSize: 20,
                    width: 300,
                    borderTop: "4px solid #243b55",
                  }}
                  key={index}
                  index={index}
                >
                  <img
                    src={item.image}
                    height="150"
                    width="100%"
                    style={{ marginRight: 30, borderRadius: 3 }}
                  />
                  <br /> <br />
                  <Button
                    color="red"
                    style={{ width: "100%" }}
                    onClick={() => {
                      openModal();
                      setNewData({
                        _id: item._id,
                      });
                    }}
                  >
                    Delete Carousel Image
                  </Button>{" "}
                  <br />
                  <p
                    style={{
                      marginTop: 8,
                      marginBottom: 1,
                      fontSize: 13,
                      color: "white",
                      backgroundColor: "#71b280",
                      borderRadius: 5,
                      padding: 5,
                      borderLeft: "8px solid black",
                    }}
                  >
                    <b>Created at: </b>
                    {item.createdAt}
                    <br />
                    <b>Updated at: </b>
                    {item.updatedAt}
                  </p>
                  <br />
                </Panel>

                {/* MODAL CONTENT */}
                <Modal backdrop={true} show={openmodal} onHide={closeModal}>
                  <Modal.Header>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure want to delete this Carousel ?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        deletePost(newdata._id);
                      }}
                    >
                      Ok
                    </Button>
                    <Button onClick={closeModal} appearance="subtle">
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* DRAWER CONTENT */}

                <Drawer
                  size="xs"
                  placement="right"
                  show={sponsordrawer}
                  onHide={closesponsordrawer}
                >
                  <Drawer.Header>
                    <Drawer.Title>Add New Carousel / Image Slider</Drawer.Title>
                  </Drawer.Header>

                  <Drawer.Body>
                    {loading ? (
                      <Loader
                        style={{ marginLeft: "40vw", marginTop: "30vh" }}
                        size="lg"
                        content="We are rendering data please wait"
                      />
                    ) : (
                      <div>
                        <h5 style={{ color: "#ee0979", fontSize: 13 }}>
                          {" "}
                          Add New Image Slider one by one
                        </h5>
                        <br /> <br />
                        <div className="form-group">
                          <label>Upload Carousel Image</label>
                          <input
                            style={{ padding: 10, marginTop: -2 }}
                            type="file"
                            onChange={(e) => setSponsorImage(e.target.files[0])}
                          />
                        </div>
                        <br />
                        <p style={{ color: "orange", fontSize: 13 }}>
                          <b>*** SUMMARY</b>
                          <br /> * please add slider image one by one).
                          <br />
                        </p>
                      </div>
                    )}
                  </Drawer.Body>
                  <Drawer.Footer>
                    <Button
                      onClick={() => postDetaileSponsor()}
                      appearance="primary"
                    >
                      Add carousel
                    </Button>
                    <Button onClick={closesponsordrawer} appearance="subtle">
                      Cancel
                    </Button>
                  </Drawer.Footer>
                </Drawer>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
