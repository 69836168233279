import React from "react";
import { Nav, Icon } from "rsuite";
import { Navbar, Dropdown } from "rsuite";
// import { NavLink } from "react-router-dom";
import { useHistory, NavLink } from "react-router-dom";

export default function index() {
  const instance = (
    <div style={{ width: "80vw", marginLeft: "10vw" }}>
      <Navbar appearance="inverse">
        <Navbar.Header>
          <a href="/" className="navbar-brand logo">
            Concern Srilanka Foundation
          </a>
        </Navbar.Header>
        <Navbar.Body>
          <Nav>
            <NavLink to="/">
              <Nav.Item icon={<Icon icon="forumbee" />}>Dashboard</Nav.Item>
            </NavLink>
            <NavLink to="/projects">
              <Nav.Item icon={<Icon icon="get-pocket" />}>Projects</Nav.Item>
            </NavLink>
            <NavLink to="/events">
              <Nav.Item icon={<Icon icon="leanpub" />}>Events</Nav.Item>
            </NavLink>
            <NavLink to="/sponsors">
              <Nav.Item icon={<Icon icon="stack-exchange" />}>
                Sponsors
              </Nav.Item>
            </NavLink>

            {/* <Nav.Item icon={<Icon icon="file-movie-o" />}>Partners</Nav.Item> */}
            <NavLink to="/carousel">
              <Nav.Item icon={<Icon icon="file-image-o" />}>Carousel</Nav.Item>
            </NavLink>

            <NavLink to="/tickers">
              <Nav.Item icon={<Icon icon="flow" />}>ScrollingText</Nav.Item>
            </NavLink>
            <NavLink to="/album">
              <Nav.Item icon={<Icon icon="file-o" />}>Albums</Nav.Item>
            </NavLink>
            <NavLink to="/videos">
              <Nav.Item icon={<Icon icon="file-movie-o" />}>Videos</Nav.Item>
            </NavLink>
            {/* <Nav.Item icon={<Icon icon="youtube-play" />}>Youtube</Nav.Item> */}
            {/* <Nav.Item icon={<Icon icon="attribution" />}>Others</Nav.Item> */}

            {/* <Dropdown icon={<Icon icon="file-o" />} title="photos and videos">
              <NavLink to="/album">
                <Dropdown.Item icon={<Icon icon="file-o" />}>
                  Photo albums
                </Dropdown.Item>
              </NavLink>
              <NavLink to="/album">
                <Dropdown.Item icon={<Icon icon="file-o" />}>
                  video Gallery
                </Dropdown.Item>
              </NavLink>
            </Dropdown> */}
          </Nav>
          <Nav pullRight>
            <Nav.Item
              icon={<Icon icon="off" />}
              onClick={() => {
                localStorage.removeItem("jwt");
                window.location.reload();
              }}
            >
              Logout
            </Nav.Item>
          </Nav>
        </Navbar.Body>
      </Navbar>
    </div>
  );

  return <div>{instance}</div>;
}
