import React, { Component } from "react";
import axios from "axios";
import CKEditor from "ckeditor4-react";
import { Icon } from "rsuite";
import BASE_URL from "./constants";
export default class FilesUploadComponent extends Component {
  constructor(props) {
    super(props);

    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setName = this.setName.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);

    this.state = {
      name: "",
      description: "",
      imgCollection: "",
      data: "",
    };
  }

  onFileChange(e) {
    this.setState({ imgCollection: e.target.files });
  }
  setName(e) {
    this.setState({ name: e.target.value });
  }

  setDescription(e) {
    this.setState({ description: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    var formData = new FormData();
    for (const key of Object.keys(this.state.imgCollection)) {
      formData.append("imgCollection", this.state.imgCollection[key]);
    }
    formData.append("name", this.state.name);
    formData.append("description", this.state.data);
    axios.post(`${BASE_URL}/upload-images`, formData, {}).then((res) => {
      alert(res.data);
    });
  }
  onEditorChange(evt) {
    this.setState({
      data: evt.editor.getData(),
    });
  }

  handleChange(changeEvent) {
    this.setState({
      data: changeEvent.target.value,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <Icon className="hand-o-left"></Icon>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <input
                type="file"
                name="imgCollection"
                onChange={this.onFileChange}
                multiple
              />

              <label>
                Name:
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
                <h4>{this.state.name}</h4>
              </label>
              <label>
                Name:
                <input
                  type="text"
                  value={this.state.description}
                  onChange={this.setDescription}
                />
                <h4>{this.state.description}</h4>
              </label>
            </div>
            <div className="App">
              <CKEditor data={this.state.data} onChange={this.onEditorChange} />
              <label>
                Change value:
                <textarea
                  defaultValue={this.state.data}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <EditorPreview data={this.state.data} />

            <div className="form-group">
              <button className="btn btn-primary" type="submit">
                Upload
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

class EditorPreview extends Component {
  render() {
    return (
      <div className="editor-preview">
        <h2>Rendered content</h2>
        <div dangerouslySetInnerHTML={{ __html: this.props.data }}></div>
      </div>
    );
  }
}
