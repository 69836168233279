import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import FileUpload from "./fileupload";
import Nav from "./screens/nav";
import Home from "./screens/home";
import Projects from "./screens/projects";
import { useHistory } from "react-router-dom";
import AddNewProject from "./screens/projects/addnew";
import AddEvent from "./screens/events/addevent";
import AddNew from "./screens/addnew";
import Events from "./screens/events";
import Ticker from "./screens/Ticker";
import Sponsors from "./screens/sponsors";
import Carousel from "./screens/carousel";
import Album from "./screens/album";
import Videos from "./screens/videos";
import Login from "./screens/login";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

const Routing = () => {
  const history = useHistory();

  useEffect(() => {
    const usercheck = localStorage.getItem("jwt");
    if (usercheck) {
      history.push("/");
    } else {
      history.push("/login");
    }

    // alert(localStorage.getItem("jwt"));///
  }, []);
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/projects">
        <Projects />
      </Route>
      <Route exact path="/events">
        <Events />
      </Route>
      <Route exact path="/add">
        <AddNew />
      </Route>
      <Route exact path="/addproject">
        <AddNewProject />
      </Route>
      <Route exact path="/addevent">
        <AddEvent />
      </Route>
      <Route exact path="/xxx">
        <Events />
      </Route>
      <Route exact path="/tickers">
        <Ticker />
      </Route>{" "}
      <Route exact path="/sponsors">
        <Sponsors />
      </Route>{" "}
      <Route exact path="/carousel">
        <Carousel />
      </Route>{" "}
      <Route exact path="/album">
        <Album />
      </Route>{" "}
      <Route exact path="/videos">
        <Videos />
      </Route>{" "}
      <Route exact path="/login">
        <Login />
      </Route>{" "}
    </Switch>
  );
};

const App = () => {
  const history = useHistory();
  const [init, setInit] = useState(false);
  useEffect(() => {
    const user = localStorage.getItem("jwt");
    if (user) {
      setInit(true);
    } else {
      setInit(false);
    }
  });
  return (
    <div className="">
      {init ? (
        <BrowserRouter>
          <Nav />
          <Routing />
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
